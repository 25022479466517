import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import GridContainer from "../layout/GridContainer/GridContainer"

// Import necessary Bulma CSS Framework components.
import "./ErrorPageWrapper.library.scss"

// Import component specific styles.
import styles from "./ErrorPageWrapper.module.scss"

const ErrorPageWrapper = props => (
  <GridContainer>
    <div className={styles.errorWrapper}>
      <div className={styles.logo}>
        <Link to="/" aria-label="Navigate to the Home page"><img src={props.logoSrc} alt={props.logoAlt} /></Link>
      </div>
      <div className={styles.errorContent}>
        <h1 className={styles.errorTitle + " h1Heavy"}>{props.title}</h1>
        <img src={props.errorImageSrc} alt={props.errorImageAlt} />
        <p className={styles.pageText + " h2Small"}>{props.pageText}</p>
        <div className={styles.homeLink}><Link to="/" aria-label="Navigate to the Home page">{props.linkText}</Link></div>
      </div>
    </div>
  </GridContainer>
)

ErrorPageWrapper.propTypes = {
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  title: PropTypes.string,
  errorImageSrc: PropTypes.string,
  errorImageAlt: PropTypes.string,
  pageText: PropTypes.string,
  linkText: PropTypes.string,
}

ErrorPageWrapper.defaultProps = {
  logoSrc: ``,
  logoAlt: ``,
  title: ``,
  errorImageSrc: ``,
  errorImageAlt: ``,
  pageText: ``,
  linkText: ``,
}

export default ErrorPageWrapper
