import React from "react"
import ErrorPageWrapper from "../components/ErrorPageWrapper/ErrorPageWrapper"
import Logo from '../images/ds_logo_crop.svg';
import Error from '../images/404Error.png';
import Layout from '../components/layout/Layout/Layout'

const logo = {
  src: Logo,
  alt: 'Destination Selsey Logo',
};

const errorImage = {
  src: Error,
  alt: "Destination Selsey 404 can't reel it in error",
};

const errorPage = () => (
  <Layout metaTitle="404" removeNav={true}>
    <ErrorPageWrapper
      logoSrc={logo.src}
      logoAlt={logo.alt}
      title="Sorry, we couldn't reel this one in ..."
      errorImageSrc={errorImage.src}
      errorImageAlt={errorImage.alt}
      pageText='404 page not found ...'
      linkText='Go home'
    />
  </Layout>
)

export default errorPage
